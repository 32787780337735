<template>
    <div class="weekCalendarContainer" v-if="selectedDates.length">
        <div v-if="fromDate" class="booking-header justify-content-between">
            <button v-if="$i18n.locale !== 'ar'" class="calendar-button" @click="gotToPreviousWeek"><img class="calendar-button-icon" src="../assets/icons/left-arrow-white.svg"/></button>
            <button v-else class="calendar-button" @click="gotToPreviousWeek"><img class="calendar-button-icon" src="../assets/icons/right-arrow-white.svg"/></button>
            <div class="selected-month">{{currentMonth}}, {{fromDate.getFullYear()}}</div>
            <button v-if="$i18n.locale === 'ar'" class="calendar-button" @click="gotToNextWeek"><img class="calendar-button-icon" src="../assets/icons/left-arrow-white.svg"/></button>
            <button v-else class="calendar-button" @click="gotToNextWeek"><img class="calendar-button-icon" src="../assets/icons/right-arrow-white.svg"/></button>
        </div>
      <div :style="{'margin-left': $i18n.locale !== 'ar' ? '2vmin' : '','margin-right': $i18n.locale === 'ar' ? '5vmin' : ''}" :class="{'right-class': $i18n.locale === 'ar'}" class="days-container">
        <div v-for="(date,index) in selectedDates" @click="selectDate(date)" class="singleDay" :key="index">
            {{ getDay(date) }}
            <div :style="{'justify-content': $i18n.locale === 'ar' ? 'right' : 'center', 'margin-right': $i18n.locale === 'ar' ? '-0.5vmin' : ''}"  style="width: 100%;display: flex;"><div class="singleDay-date" :class="{'singleDay-date-selected': selectedDate.toDateString() === date.toDateString()}"><div style="margin: auto">{{date.getDate()}}</div></div></div>
        </div>
      </div>

        <div class="times-container">
            <LbrxBookingTimePicker :times="times" @time-selected="selectTime" v-model="loadingSlots" :theme-template="themeTemplate"></LbrxBookingTimePicker>
        </div>
        <lbrx-kiosk-popup v-if="showPopup" @close="showPopup = false" v-model="showPopup" :closable="isClosable" :box-height="boxHeight" :content="boxContent" :icon="boxIcon" :text-color="boxTextColor" :box-background="boxBackGround" :icon-color="boxIconColor"></lbrx-kiosk-popup>

    </div>
</template>

<script>
import moment from "moment";
import colorShader from "@/_helpers/color-shader";
import LbrxBookingTimePicker from "@/components/LbrxBookingTimePicker.vue";
import {kioskService} from "@/_services";
import LbrxKioskPopup from "@/components/LbrxKioskPopup.vue";
export default  {
    name: 'LbrxSingleWeekCalendar',
    components: {LbrxKioskPopup, LbrxBookingTimePicker},
    props: {
        themeTemplate: {required: true, type:Object},
        modelValue: {required: true, type:String},
    },
    watch: {
        modelValue :{
            handler:function (){
                this.times = [];
                if(this.selectedDate) {
                    this.loadingSlots = true;
                    this.selectDate(this.selectedDate);
                }
            },
            deep: true
        }
    },
    emits: ['selectDates'],
    data() {
        return{
            times:[],
            fromDate:null,
            toDate:null,
            selectedDates:[],
            selectedTime:null,
            selectedDate:null,
            loadingSlots:false,
            boxHeight:'40%',
            boxContent: '',
            boxIcon: 'loader',
            boxTextColor: '',
            isClosable: false,
            boxBackGround: "#FFFFFF",
            boxIconColor: this.themeTemplate.secondaryColor,
            showPopup: false,

        }
    },
    created() {
        this.setDates(new Date(),1, 7);
        this.selectDate(new Date());
    },
    computed: {
        currentMonth() {
            let local = this.$i18n.locale;
            if(local === 'ar') {
                local+="-tn";
            }
            return moment(this.fromDate).locale(local).format("MMM").charAt(0).toUpperCase() + moment(this.fromDate).locale(local).format("MMM").slice(1);
        },
        getCalendarDayColor() {
            return this.themeTemplate.booking.calendarDayBackgroundColor;
        },
        getTextColor() {
            return this.themeTemplate.textColor;
        },
        getCalndarButtonBg() {
            return this.themeTemplate.booking.calendarButtonBackground;
        },

    },
    methods: {
        selectTime(time) {
            this.selectedTime = time;
            this.$emit('selectDates', {date: this.selectedDate, time: this.selectedTime});
        },
        showErrorPopup(errorText) {
            this.showPopup = false;
            this.boxIcon = 'error';
            this.isClosable = true;
            this.boxContent = errorText;
            this.boxTextColor = '#EB5757'
            this.boxBackGround = "#FFFFFF";
            this.boxIconColor = '#EB5757';
            this.boxHeight = "40%";
            this.showPopup = true;
        },
        selectDate(date) {
            this.selectedDate = date;
            this.times = [];
            this.loadingSlots = true;
            const payload = {business_id: JSON.parse(window.localStorage.getItem('business')).id, date: moment(this.selectedDate).format("YYYY-MM-DD"), department: this.modelValue.department.id, user: null}
            if(this.modelValue.agent != null) {
              payload.member_id = this.modelValue.agent.id;
            } else {
              payload.member_id = null;
            }
            kioskService.getAvailableSlots(payload)
                .then((res)=>{
                    console.log(res);
                    this.times = res.slots
                    this.loadingSlots = false;
                })
                .catch((err)=>{
                    console.log(err);
                    this.loadingSlots = false;
                })
        },
        gotToNextWeek() {
            this.loadingSlots = true;
            this.times = [];
            this.setDates(this.fromDate,8, 7);
            //get bookings from 'fromDate' to 'toDate' then se 'loading' to false
            this.loadingSlots = false;
        },
        gotToPreviousWeek() {
            this.loadingSlots = true;
            this.times = [];
            //get bookings from 'fromDate' to 'toDate' then se 'loading' to false
            this.setDates(this.fromDate,-6, 7);
            this.loadingSlots = false;
        },
        getDay(date) {
            let local = this.$i18n.locale;
            if(local === 'ar') {
                local+="-tn";
            }
            return moment(date).locale(local).format("ddd");
        },
        setDates(today, plusFrom, plusTo) {
            const date = new Date(today);
            this.fromDate = new Date(date.setDate(date.getDate() - date.getDay()+(plusFrom)));
            this.toDate = new Date(date.setDate(date.getDate() - date.getDay() + (plusTo)));
            //set selected days
            const selected = []
            let dt = new Date(this.fromDate);
            dt = new Date(dt.setDate(dt.getDate() - dt.getDay()));
            for(let i=0;i<7;i++) {
                selected.push(dt);
                dt = new Date(dt.setDate(dt.getDate()+1));
            }
            this.selectedDates = [];
            this.selectedDates = selected;
        },
    },
}
</script>

<style scoped>
.booking-header{
    margin: 2vmin;
    display: flex;
}
.days-container{
    display: flex;
    width: 100%;
}
.singleDay{
    width: 14%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    font-family: 'Inter',serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    /* identical to box height */


    /* Blue Biat */

    color: v-bind(getCalendarDayColor);
}
.singleDay-date{
    display: flex;
    height: 32px;
    width: 32px;
    margin-top: 0.5vmin;

    font-family: 'Inter',serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 17px;
    /* identical to box height */


    color: #9C9C9C;
}
.singleDay-date-selected {
    background: v-bind(getCalendarDayColor);
    color: v-bind(getTextColor);
    font-weight: 500;
}
.calendar-button-icon{
    height: 2.3vmin;
}
.calendar-button{
    margin-top:-0.2vmin;
    background: v-bind(getCalndarButtonBg);
    border: none;
    min-width: 4.5vmin;
    min-height: 4.2vmin;
}

.selected-month{
    font-family: 'Inter',serif;
    font-style: normal;
    font-weight: 300;
    font-size: 3.8vmin;
    line-height: 29px;
    /* identical to box height */


    /* Primary */

    color: v-bind(getCalendarDayColor);
}
.weekCalendarContainer{
    display: flex;
    flex-direction: column;
}

.times-container{
    background: v-bind(containerBg);
    overflow-y: hidden;
    height: 50vh !important;
    padding-top: 5vmin;
    justify-content: center !important;
    overflow-x: hidden;
    display: flex;
}
</style>