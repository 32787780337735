<template>
    <div class="services-pagination">
        <div :style="{'opacity': modelValue === 1 ? '0.5' : ''}" class="service-page">
            <div @click="$emit('prevPage')" style="margin: auto">&lt;</div>
        </div>
        <div class="service-page" @click="$emit('setPage', index)" v-for="(index) in pagesCount" :style="{'background': modelValue === index ? themeTemplate.primaryColor : '', 'color': modelValue === index ? themeTemplate.textColor : ''}"  :key="index">
            <div style="margin: auto">{{index}}</div>
        </div>
        <div @click="$emit('nextPage')" :style="{'opacity': modelValue === pagesCount ? '0.5' : ''}"  class="service-page">
            <div style="margin: auto">&gt;</div>
        </div>
    </div>
</template>
<script>
import colorShader from "@/_helpers/color-shader";

export default  {
    name: "LbrxServicesPaginator",
    props: {
        pagesCount: {required:true,type:Number},
        modelValue: {required:true,type:Number},
        themeTemplate: {required:true,type:Object},
    },
    emits: ['prevPage', 'nextPage', 'setPage'],
    computed: {
        getPrimary() {
            return this.themeTemplate.primaryColor;
        }
    }
}
</script>

<style scoped>
.services-pagination{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -4vmin;
}
.service-page{
    font-size: 18px !important;
    color: v-bind(getPrimary);
    width: 27px;
    height: 27px;
    margin-left: 0.8vmin;
    margin-right: 0.8vmin;
    display: flex;
    border-radius: 3000px;
    background: rgba(191, 182, 171, 0.3)
}
</style>