<template>
    <div>
        <IconAddCircle v-if="iconName.length == 0" class="w-11 h-fit mx-auto" :size="24" :color="iconColor" />
        <IconCar v-if="iconName == 'IconCar'" class="w-11 h-fit mx-auto" :size="24" :color="iconColor" />
        <IconMoto v-if="iconName == 'IconMoto'" class="w-11 h-fit mx-auto" :size="24" :color="iconColor" />
        <IconHealthcare v-if="iconName == 'IconHealthcare'" class="w-11 h-fit mx-auto" :size="24" :color="iconColor" />
        <IconTravel v-if="iconName == 'IconTravel'" class="w-11 h-fit mx-auto" :size="24" :color="iconColor" />
        <IconHome v-if="iconName == 'IconHome'" class="w-11 h-fit mx-auto" :size="24" :color="iconColor" />
        <IconProfessional v-if="iconName == 'IconProfessional'" class="w-11 h-fit mx-auto" :size="24" :color="iconColor" />
        <IconForesight v-if="iconName == 'IconForesight'" class="w-11 h-fit mx-auto" :size="24" :color="iconColor" />
        <IconOther v-if="iconName == 'IconOther'" class="w-11 h-fit mx-auto" :size="24" :color="iconColor" />
    </div>
</template>
<script>
import IconAddCircle from "./icons/IconAddCircle.vue"
import IconCar from "./icons/IconCar.vue" 
import IconMoto from "./icons/IconMoto.vue" 
import IconHealthcare from "./icons/IconHealthcare.vue"
import IconTravel from "./icons/IconTravel.vue"
import IconHome from "./icons/IconHome.vue"
import IconProfessional from "./icons/IconProfessional.vue"
import IconForesight from "./icons/IconForesight.vue"
import IconOther from "./icons/IconOther.vue"
export default {
    name: 'LbrxIconSelector',
    components: {
        IconAddCircle,
        IconCar,
        IconMoto,
        IconHealthcare,
        IconTravel,
        IconHome,
        IconProfessional,
        IconForesight,
        IconOther
    },
    props: {
        iconName: {
            required: true,
            value: String
        },
        iconColor: {
            required: true,
            value: String
        }
    },
    data() {
        return {
            themeTemplate: null,
        }
    }
}
</script>

<style scoped></style>