<template>
<div class="firstThemeContainer">
    <div class="servicesPaginationsContainer">
  <div v-if="!popup" class="languageSelectContainer">
      <div @click="changeLanguage()" class="languageButtons justify-content-between">
      <div class="languageButton" @click="$emit('setLanguage', 'ar')" :class="{'selectedLeftButton': $i18n.locale === 'ar'}">
          <div style="margin: auto">العربية</div>
      </div>
      <div class="languageButton"  @click="$emit('setLanguage', 'fr')" :class="{'selectedRightButton': $i18n.locale !== 'ar'}">
        <div style="margin: auto">Français</div>
      </div>
      </div>
  </div>
  <div class="mainContainer">
        <div v-long-press:@click="()=>handleLongPress(service)" @mouseup="handleClick(service)" @touchend="()=>handleTouch(service)" v-for="(service, index) in servicesPage" :key="index" :id="'service'+service.id" :class="{'borderRight': index%2 ===0, 'borderLeft': index%2 !==0}" :style="{'float': index%2 !==0  ? 'right': 'left', 'opacity': service.disabled ? '0.7':'1', 'direction': index%2 !==0  ? 'rtl': 'ltr'}" class="singleService justify-content-between">
            <div  v-if="service.translations && JSON.parse(service.translations)[$i18n.locale] != null" class="labelContainer" :style="{'float': index%2 ===0  ? 'left': 'right','margin-right': index%2 !==0  ? '2vmin': '', 'line-height': getFontSize(JSON.parse(service.translations)[$i18n.locale]), 'font-size': getFontSize(JSON.parse(service.translations)[$i18n.locale])}">{{JSON.parse(service.translations)[$i18n.locale]}} <span style="font-weight:600;margin-left:5px;margin-right:5px" v-if="service.checked  && service.qte">(x{{service.qte}})</span></div>
            <div v-else-if="service.title" class="labelContainer" :style="{'justify-content': index%2 ===0  ? 'left': 'right','margin-right': index%2 !==0  ? '2vmin': '', 'font-size': getFontSize(service.title) , 'line-height': getFontSize(service.title)}">{{service.title}} <span style="font-weight:600;margin-left:5px;margin-right:5px" v-if="service.checked  && service.qte">(x{{service.qte}})</span></div>
            <div v-if="service.title" :style="{'background': service.checked ? themeTemplate.secondaryColor :'', 'border': service.checked ? '0.6vmin solid '+makeSecondaryColorLighter : index%2 ===0 ? '0.6vmin solid '+getPrimary : '', 'font-size': getFontSize(service.title) , 'line-height': getFontSize(service.title)}" class="theme-checkbox"></div>
        </div>
  </div>
      <lbrx-services-paginator v-if="pagesCount > 1" @next-page="nextPage" @prev-page="prevPage" @set-page="setPage" :themeTemplate="themeTemplate" v-model="currentPage" :pages-count="pagesCount"></lbrx-services-paginator>
      <div v-else class="paginator-holder">

      </div>
    </div>
  <div v-if="!popup && showNextButton" :style="{'margin-bottom': popup ? '6.5vmin': ''}" class="nextButtonContainer">
      <lbrx-button id="validateButton" v-model="isDisabled"
                   @clicked="$emit('validate')" border-radius="20vmin" :label="phoneNumberNotAllowed ? $t('Kiosk.printTicketButton') :$t('Kiosk.nextButton')" :color="themeTemplate.textColor"
                   :background="makeColorDarker" height="8vmin" width="20%" font-size="1.3"></lbrx-button>
  </div>
</div>
</template>

<script>
import colorShader from "@/_helpers/color-shader";
import LbrxButton from "@/components/LbrxButton.vue";
import LbrxServicesPaginator from "@/components/LbrxServicesPaginator.vue";
import longPressDirective from '../_helpers/longPressDirective';
export default {
    name: "LbrxKioskFirstTheme",
    components: {LbrxServicesPaginator, LbrxButton},
    directives: {
        'long-press': longPressDirective,
    },
    props: {
        themeTemplate: {required: true, type: Object},
        services: {required: true, type: Array},
        modelValue: {required: true, type: Boolean},
        popup: {required: true, type: Boolean},
    },
    emits: ['validate', 'checkService', 'long-press', 'setLanguage'],
    data() {
        return {
            longPress:false,
            currentPage:1,
            pagesCount:0,
            itemsPerPage:12,
        }
    },
    methods: {
        getFontSize(label) {
            if(label.length > 100) {
                return "16px";
            }
            if(label.length > 50) {
                return "21px";
            }
            return "26px";
        },
        handleLongPress(service) {
            this.longPress = true;
            this.$emit('long-press', service);
        },
        handleClick(service) {
            this.$emit('checkService', service);
        },
        handleTouch(service) {
            if(this.longPress) {
                this.longPress = false;
                return;
            }
            this.$emit('checkService', service);
        },
        changeLanguage() {
            if(this.language === 'fr')
                this.language = 'ar';
            else
                this.language = 'fr';
        },
        setPage(pageNumber) {
            if(this.currentPage !== pageNumber)
            this.currentPage = pageNumber;
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.pagesCount) {
                this.currentPage++;
            }
        }
    },
    created() {
        this.pagesCount = Math.ceil(this.services.length / this.itemsPerPage);
        if(this.popup) {
            this.itemsPerPage = 2;
        }
    },
    computed: {
        showNextButton () {
            return (+JSON.parse(JSON.parse(window.localStorage.getItem('kiosk')).config).allow_multiple_services || JSON.parse(window.localStorage.getItem('kiosk')).allow_sms_ticket);
        },
        phoneNumberNotAllowed() {
            return !JSON.parse(window.localStorage.getItem('kiosk')).allow_sms_ticket;
        },
        servicesPage() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            return this.services.slice(startIndex, startIndex + this.itemsPerPage);
        },
        isDisabled() {
            return this.modelValue;
        },
        makeSecondaryColorLighter(){
            return colorShader.methods.newShade(this.themeTemplate.secondaryColor, 50);
        },
        makeColorDarker(){
            return colorShader.methods.newShade(this.themeTemplate.primaryColor, -50);
        },
        makeColorLighter(){
            return colorShader.methods.newShade(this.themeTemplate.primaryColor, 50);
        },
        getPrimary() {
            return this.themeTemplate.primaryColor;
        },
        getTextColor() {
            return this.themeTemplate.textColor;
        }
    }
}
</script>

<style scoped>
.servicesPaginationsContainer{
    height: 88%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.paginator-holder{
    height: 3vmin;
}
.borderLeft{
    text-align: right;
    border-top-left-radius: 20vmin;
    border-bottom-left-radius: 20vmin;
}
.borderRight{
    text-align: left;
    border-top-right-radius: 20vmin;
    border-bottom-right-radius: 20vmin;
}
.mainContainer{
    padding-bottom: 2vmin;
    overflow: hidden;
    min-width: 104.35%;
    margin-left: -2vmin;
    padding-top: 3vmin;
}
.singleService{
    display: flex;
    cursor: pointer;
    background: linear-gradient(127.19deg, v-bind(getPrimary) 0.56%, v-bind(makeColorLighter) 76.01%) !important;
    width: 45%;
    height: 70px;
    margin-bottom: 2vmin;
}
.labelContainer{
    padding-bottom: 3px;
    padding-top: 3px;
    width: 78%;
    text-align: left;
    display: flex;
    margin: auto 1vmin;
    padding-right: 5%;
    white-space: break-spaces;
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 28px;
    color: v-bind(getTextColor);
}
.theme-checkbox{
    background: v-bind(makeColorDarker);
    border:0.6vmin solid v-bind(makeColorLighter);
    border-radius: 1000px;
    height: 62px;
    width: 62px;
    margin: auto 0.7%;
}
.firstThemeContainer{
    overflow-x: hidden;
    padding: 2vmin;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.languageButton{
    width: 50%;
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2.1vw;
    display: flex;
    line-height: 3.5vmin;
    color: v-bind(getPrimary)
}
.selectedLeftButton{
    margin-left: -3vmin;
    width: 80%;
    border-radius: 20vmin;
    background: linear-gradient(127.19deg, v-bind(getPrimary) 0.56%, v-bind(makeColorLighter) 76.01%) !important;
    color: v-bind(getTextColor);
}

.selectedRightButton {
    margin-right: -3vmin;
    width: 80%;
    border-radius: 20vmin;
    background: linear-gradient(127.19deg, v-bind(getPrimary) 0.56%, v-bind(makeColorLighter) 76.01%) !important;
    color: v-bind(getTextColor);
}
.languageButtons {
    cursor: pointer;
    padding-left:3vmin;
    padding-right:3vmin;
    display: flex;
    width: 30vw;
    border: 1px solid v-bind(getPrimary);
    border-radius: 20vmin;
}
.languageSelectContainer{
    display: flex;
    width: 100%;
    justify-content: center;
    height: 70px;
}

.nextButtonContainer{
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>