<template>
    <div>
        <div class="login-container">
            <lbrx-welcome-interface></lbrx-welcome-interface>
            <div class="form-footer-container">
                <lbrx-login-logo></lbrx-login-logo>
                <div class="login-form-container">
                    <div style="width: 90%">
                        <div v-if="errorLogin" class="error-container">
                            <lbrx-error-notification :label="errors[errors.length-1]" color="#FF5757"
                                                     background="rgba(255, 158, 152, 0.5)" height="10" width="96%"
                                                     font-size="0.7" icon="error-icon"></lbrx-error-notification>
                        </div>
                        <div class="form-container">
                            <div class="auth-title">
                                Mode maintenance
                            </div>
                            <img class="maintenance-img" src="../../assets/icons/web-browser-icon.svg"/>
                            <div class="auth-sub-title">
                                Veuillez patienter avec nous<br>
                                Nous travaillons à résoudre le problème
                            </div>
                            <div class="auth-code auth-second-code">
                                +216 36 36 43 45<br>
                                support@liberrex.com
                            </div>
                        </div>
                    </div>
                </div>
                <div class="login-footer">
                    <div class="footer-container">
                        <div class="copyright">
                            liberrex.com © Copyright {{ new Date().getFullYear() }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import LbrxErrorNotification from "@/components/LbrxErrorNotification.vue";
import LbrxWelcomeInterface from "@/components/LbrxWelcomeInterface.vue";
import LbrxLoginLogo from "@/components/LbrxLoginLogo.vue";
import router from "@/router";

export default {
    name: "MaintenancePage",
    components: {
        LbrxLoginLogo,
        LbrxWelcomeInterface,
        LbrxErrorNotification
    },
    data() {
        return {}
    },
    mounted() {
        if (JSON.parse(localStorage.getItem('display')).maintenance === 0) {
           router.push('/kioskWaitingQueue');
        }
    },
    methods: {}
}
</script>

<style scoped>
@media (max-width: 1200px) {
    .form-footer-container {
        justify-content: center !important;
        width: 100% !important;
    }
}

.error-container {
    display: flex;
    justify-content: center;
    margin-bottom: 2vmin;
}

.footer-container {
    text-align: center;
    width: 50%;
}

.login-footer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.login-form-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin: 6vmin auto auto;
}

.login-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: auto !important;
}

.form-footer-container {
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-content: space-between;
}

.auth-title {

    font-family: 'Exo 2', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 26px;

    /* Medium Gray */

    color: #8A8A8A;
}

.auth-second-code {
    font-size: 22px !important;
    line-height: 22px !important;
    margin-top: 3vmin !important;
}

.auth-code {
    font-family: 'Exo 2', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13vmin;
    line-height: 14vmin;
    margin-top: 1.5vmin;

    color: #8A8A8A;
}

.auth-sub-title {
    font-family: 'Exo 2', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;

    /* Primary */

    color: #008FCA;
    margin-top: 3vmin;
    margin-bottom: 1.6vmin;
}

.maintenance-img {
    height: 16vmin;
    margin-top: 3vmin;
    opacity: 0.1;
}
</style>
