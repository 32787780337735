<template>
    <div id="servicesPopup" v-if="modelValue" class="popup-container">
        <Transition name="fade">
            <div v-if="showPopup" class="popup-box">
                <div class="popup-title">
                    {{$t('Kiosk.servicesPopupTitle')}}
                </div>
                    <div class="popup-body">
                        <lbrx-kiosk-first-theme v-model="validating" :popup="true" @long-press="openQuantity" @check-service="checkService" @validate="validateServices" v-if="themeTemplate.theme === 'firstTheme'" :theme-template="themeTemplate" :services="service.child"
                                                ></lbrx-kiosk-first-theme>
                        <lbrx-kiosk-second-theme v-model="validating" :popup="true" @long-press="openQuantity" @check-service="checkService" @validate="validateServices" v-else-if="themeTemplate.theme === 'secondTheme'" :theme-template="themeTemplate" :services="service.child"
                                                 ></lbrx-kiosk-second-theme>
                        <lbrx-kiosk-third-theme v-model="validating" :popup="true" @long-press="openQuantity" @check-service="checkService" @validate="validateServices" v-else-if="themeTemplate.theme === 'thirdTheme'" :theme-template="themeTemplate" :services="service.child"
                                                ></lbrx-kiosk-third-theme>
                        <lbrx-kiosk-fourth-theme v-model="validating" :popup="true" @long-press="openQuantity" @check-service="checkService" @validate="validateServices" v-else-if="themeTemplate.theme === 'fourthTheme'" :theme-template="themeTemplate" :services="service.child"
                                                 ></lbrx-kiosk-fourth-theme>
                        <lbrx-kiosk-fifth-theme v-model="validating" :popup="true" @long-press="openQuantity" @check-service="checkService" @validate="validateServices" v-else-if="themeTemplate.theme === 'fifthTheme' || themeTemplate.theme === 'seventhTheme'" :services="service.child" :theme-template="themeTemplate"></lbrx-kiosk-fifth-theme>
                        <lbrx-kiosk-sixth-theme v-model="validating" :popup="true" @long-press="openQuantity" @check-service="checkService" @validate="validateServices" v-else-if="themeTemplate.theme === 'sixthTheme' || themeTemplate.theme === 'eighthTheme'" :services="service.child" :theme-template="themeTemplate"></lbrx-kiosk-sixth-theme>
                    </div>
            </div>
        </Transition>
        <lbrx-quantity-picker-popup v-if="showQuantityPicker" @submit-quantity="setQuantity" @close="closeThis" v-model="showQuantityPicker" :max="20" :min="1" :themeTemplate="themeTemplate"></lbrx-quantity-picker-popup>
    </div>
</template>

<script>


import LbrxKioskFifthTheme from "@/components/LbrxKioskFifthTheme.vue";
import LbrxKioskSecondTheme from "@/components/LbrxKioskSecondTheme.vue";
import LbrxKioskFirstTheme from "@/components/LbrxKioskFirstTheme.vue";
import LbrxKioskThirdTheme from "@/components/LbrxKioskThirdTheme.vue";
import LbrxKioskFourthTheme from "@/components/LbrxKioskFourthTheme.vue";
import LbrxQuantityPickerPopup from "@/components/LbrxQuantityPickerPopup.vue";
import LbrxKioskSixthTheme from "@/components/LbrxKioskSixthTheme.vue";
export default {
    name: 'LbrxKioskServicesPopup',
    components: {
        LbrxKioskSixthTheme,
        LbrxQuantityPickerPopup,
        LbrxKioskFourthTheme,
        LbrxKioskThirdTheme,
        LbrxKioskFirstTheme,
        LbrxKioskSecondTheme,
        LbrxKioskFifthTheme,
    },
    props: {
        modelValue:Boolean,
        service: {required:true,type:Object},
        themeTemplate: {required:true,type:Object},
    },
    data() {
        return {
            showQuantityPicker:false,
            showPopup:false,
            loading:false,
            setQuantityFor:null,
        }
    },
    computed: {
        getPrimary() {
            return this.themeTemplate.primaryColor;
        },
        getBg() {
            return "#FFFFFF"
        }
    },
    methods: {
        setQuantity(qte) {
            this.setQuantityFor.qte = qte;
        },
        openQuantity(service) {
            if(!JSON.parse(JSON.parse(window.localStorage.getItem("kiosk")).config).allow_quantity_services)
                return;
            if(service.child)
                if(service.child.length)
                    return;
            this.setQuantityFor = this.service.child[this.service.child.indexOf(service)];
            this.showQuantityPicker = true;
            // eslint-disable-next-line vue/no-mutating-props
            this.service.child[this.service.child.indexOf(service)].checked = true;
        },
        checkService(service) {
            this.service.child.forEach((serv)=>{
                if(serv.id !== service.id) {
                    serv.checked = false;
                    serv.qte = null;
                }
            })
            // eslint-disable-next-line vue/no-mutating-props
            this.service.child[this.service.child.indexOf(service)].checked = !this.service.child[this.service.child.indexOf(service)].checked;
            if(!service.checked)
                service.qte = null;
            this.closeThis();
        },
        validateServices() {
            this.closeThis();
        },
        closeThis() {
            this.showPopup = false;
            setTimeout(()=>{
                this.$emit('close');
            },700)
        },
    },
    created() {
        document.addEventListener('keyup',(event)=>{
            event.key === 'Escape' ? this.closeThis():null;
        });
        setTimeout(()=>{
            document.addEventListener('click',()=>{
                this.closable ? this.closeThis():null;
            });
        },1500);
        this.loading = false;
        setTimeout(()=>{
            this.showPopup = true;
        },1)
    },
}
</script>

<style scoped>


.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.popup-container{
    background: rgba(0,0,0,0.7);
    display: flex !important;
    justify-content: center !important;
}
.popup-box {
    margin: auto;
    overflow-y: hidden !important;
    width: 70%;
    height: 60%;
    background: v-bind(getBg);
}
.popup-body {
    overflow-y: auto !important;
    margin-top: 5vmin;
    height: 80% !important;
}
.popup-title{
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    font-size: 4.5vmin;
    line-height: 5vmin;
    text-align: center;
    padding: 2vmin;
    color: v-bind(getPrimary);
    display: flex;
    justify-content: center;
    margin-top: 5vmin;
}
</style>