<template>
  <div class="fifthThemeContainer">
    <lbrx-kiosk-header v-if="!popup" :themeTemplate="themeTemplate" :logo="themeTemplate.header.logo" :hasBorderRadius="themeTemplate.header.borderRadius"  v-model="isDisabled" :primaryColor="themeTemplate.primaryColor" :secondaryColor="themeTemplate.secondaryColor" :text-color="themeTemplate.textColor" :header-background="themeTemplate.header.headerBackground"></lbrx-kiosk-header>
    <div v-if="!popup"  class="titleContainer">
      <div class="title">
        {{ this.themeTemplate.allowCategory == 1 && !categoryChosen ? getCategoryTitle : $t('Kiosk.servicesTitle') }}
      </div>
    </div>
    <div class="mainContainer" :style="{'padding-left': $i18n.locale !== 'ar' ? '4vmin' : '','padding-right': $i18n.locale === 'ar' ? '4vmin !important' : ''}">
      <div v-if="themeTemplate.allowCategory == 1 && !categoryChosen" :class="{'right-class': $i18n.locale === 'ar'}" class="row">
        <div :class="'col-4'" v-for="(category, index) in categoriesPage" :id="'category'+category.id" :key="index" >
          <div :style="{'background': category.checked ? themeTemplate.services.selectedServiceBackgroundColor : '', 'opacity': category.disabled ? '0.7':'1'}" :title="category.name" @mouseup="handleClickCategory(category)" class="singleService">
            <div :style="{'color': category.checked ? themeTemplate.services.selectedServiceTextColor : '', 'font-size': getFontSize(JSON.parse(category.translations)[$i18n.locale]) , 'line-height': getFontSize(JSON.parse(category.translations)[$i18n.locale])}" :class="{'right-class': $i18n.locale === 'ar'}" v-if="category.translations && JSON.parse(category.translations)[$i18n.locale] != null" class="labelContainer"><LbrxIconSelector :iconName="category.icon" :iconColor="getServiceTextColor" />{{JSON.parse(category.translations)[$i18n.locale]}}</div>
            <div :style="{'color': category.checked ? themeTemplate.services.selectedServiceTextColor : '', 'font-size': getFontSize(category.name) , 'line-height': getFontSize(category.name)}" :class="{'right-class': $i18n.locale === 'ar'}" v-else-if="category.name" class="labelContainer"><LbrxIconSelector :iconName="category.icon" :iconColor="getServiceTextColor" />{{category.name}}</div>
          </div>
        </div>
      </div>
      <div v-else :class="{'right-class': $i18n.locale === 'ar'}" class="row">
        <div :class="{'col-6': popup || servicesPage.length < 3, 'col-4': !popup && servicesPage.length > 2}" v-for="(service, index) in servicesPage" :id="'service'+service.id" :key="index" >
          <div :style="{'background': service.checked ? themeTemplate.services.selectedServiceBackgroundColor : '', 'opacity': service.disabled ? '0.7':'1'}" :title="service.title" v-long-press:@click="()=>handleLongPress(service)" @mouseup="handleClick(service)" @touchend="()=>handleTouch(service)" class="singleService">
            <div :style="{'color': service.checked ? themeTemplate.services.selectedServiceTextColor : '', 'font-size': getFontSize(JSON.parse(service.translations)[$i18n.locale]) , 'line-height': getFontSize(JSON.parse(service.translations)[$i18n.locale])}" :class="{'right-class': $i18n.locale === 'ar'}" v-if="service.translations && JSON.parse(service.translations)[$i18n.locale] != null" class="labelContainer">{{JSON.parse(service.translations)[$i18n.locale]}} <span style="font-weight:600;margin-left:5px;margin-right:5px" v-if="service.checked  && service.qte">(x{{service.qte}})</span></div>
            <div :style="{'color': service.checked ? themeTemplate.services.selectedServiceTextColor : '', 'font-size': getFontSize(service.title) , 'line-height': getFontSize(service.title)}" :class="{'right-class': $i18n.locale === 'ar'}" v-else-if="service.title" class="labelContainer">{{service.title}}  <span style="font-weight:600;margin-left:5px;margin-right:5px" v-if="service.checked && service.qte">(x{{service.qte}})</span></div>
          </div>
        </div>
      </div>
    </div>
    <lbrx-services-paginator v-if="pagesCount > 1  && allowPagination" @next-page="nextPage" @prev-page="prevPage" @set-page="setPage" :theme-template="themeTemplate" v-model="currentPage" :pages-count="pagesCount"></lbrx-services-paginator>
    <div v-else class="paginator-holder">

    </div>
    <span v-if="!popup">
    <div v-if="!booking && showNextButton" :style="{'margin-bottom': popup ? '8.5vmin': '2vmin'}" class="nextButtonContainer">
        <lbrx-button v-model="isDisabled"
                     @clicked="$emit('validate')" :box-shadow="'0px 4px 4px rgba(0, 0, 0, 0.25)'" :label="popup ? $t('Kiosk.closeButton') :$t('Kiosk.nextButton')" :color="themeTemplate.services.mainButtonTextColor"
                     :background="themeTemplate.services.mainButtonBackground" height="8vmin" width="31%" font-size="1.3"></lbrx-button>
    </div>

    <div v-else class="buttons-container">
      <div v-if="themeTemplate.allowCategory == 1 && !categoryChosen" class="nextButtonContainer" :style="{'margin-bottom': popup ? '8.5vmin': '2vmin'}">
        <lbrx-button id="cancelButton" v-model="isDisabled"
                             @clicked="$emit('cancel')" :box-shadow="'0px 4px 4px rgba(0, 0, 0, 0.25)'" :label="$t('Kiosk.backButton')" :color="themeTemplate.services.mainButtonTextColor"
                             :background="'transparent'" :border="'1px solid '+ getBackButtonBorder" height="8vmin" width="31%" font-size="1.3"></lbrx-button>
      </div>
        <div v-else class="row">
            <div :class="{'col-6': servicesPage.length < 3, 'col-4': servicesPage.length > 2}">
                <lbrx-button id="cancelButton" v-model="isDisabled"
                             @clicked="$emit('cancel')" :box-shadow="'0px 4px 4px rgba(0, 0, 0, 0.25)'" :label="$t('Kiosk.backButton')" :color="themeTemplate.services.mainButtonTextColor"
                             :background="'transparent'" :border="'1px solid '+ getBackButtonBorder" height="8vmin" width="100%" font-size="1.3"></lbrx-button>
            </div>
            <div class="col-4" v-if="servicesPage.length >= 3 && showNextButton"></div>
            <div v-if="showNextButton" :class="{'col-6': servicesPage.length < 3, 'col-4': servicesPage.length > 2}">
                <lbrx-button id="validateButton" v-model="isDisabled"
                             @clicked="$emit('validate')" :box-shadow="'0px 4px 4px rgba(0, 0, 0, 0.25)'" :label="phoneNumberNotAllowed ? $t('Kiosk.printTicketButton') :$t('Kiosk.nextButton')" :color="themeTemplate.services.mainButtonTextColor"
                             :background="booking ? themeTemplate.booking.calendarBackground :themeTemplate.services.mainButtonBackground" height="8vmin" width="100%" font-size="1.3"></lbrx-button>
            </div>
        </div>
    </div>
  </span>
    <div v-if="!popup"  class="footer-container">
      <the-footer :color="themeTemplate.textColor" v-model="onLine" :background="themeTemplate.footer.footerBackground"></the-footer>
    </div>
  </div>
</template>

<script>
import LbrxButton from "@/components/LbrxButton.vue";
import LbrxKioskHeader from "@/components/LbrxKioskHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import LbrxServicesPaginator from "@/components/LbrxServicesPaginator.vue";
import longPressDirective from '../_helpers/longPressDirective';
import LbrxIconSelector from "@/components/LbrxIconSelector.vue";
export default {
  name: "LbrxKioskFifthTheme",
  components: {LbrxServicesPaginator, TheFooter, LbrxKioskHeader, LbrxButton, LbrxIconSelector},
  props: {
    themeTemplate: {required: true, type: Object},
    services: {required: true, type: Array},
    modelValue: {required: true, type: Boolean},
    popup: {required: true, type: Boolean},
    booking: {required: false, type: Boolean},
    categories: {required: false, type: Array},
    categoryChosen: {required: false, type: Boolean, default: true},
  },
  directives: {
    'long-press': longPressDirective,
  },
  emits: ['validate', 'checkService', 'cancel', 'long-press', 'checkCategory'],
  data() {
    return {
      allowPagination:false,
      language: 'fr',
      currentPage:1,
      pagesCount:0,
      itemsPerPage:15,
      onLine: navigator.onLine,
    }
  },
  created() {

    if(this.themeTemplate.allowPagination != null)
      this.allowPagination = this.themeTemplate.allowPagination;
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
    if(this.popup) {
      this.itemsPerPage = 2;
    } else {
      this.itemsPerPage = Math.floor(window.innerHeight / 180) *3;
      console.log(this.itemsPerPage);
    }
    this.pagesCount = Math.ceil(this.services.length / this.itemsPerPage);
  },
  methods: {
    getFontSize(label) {
      if(label.length > 100) {
        return "14px";
      }
      if(label.length > 50) {
        return "19px";
      }
      return "24px";
    },
    handleLongPress(service) {
      console.log(service);
      this.longPress = true;
      this.$emit('long-press', service);
    },
    handleClick(service) {
      this.$emit('checkService', service);
    },
    handleClickCategory(category) {
      this.$emit('checkCategory', category);
    },
    handleTouch(service) {
      if(this.longPress) {
        this.longPress = false;
        return;
      }
      this.$emit('checkService', service);
    },
    setPage(pageNumber) {
      if(this.currentPage !== pageNumber)
        this.currentPage = pageNumber;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.pagesCount) {
        this.currentPage++;
      }
    },
    changeLanguage() {
      if(this.language === 'fr')
        this.language = 'ar';
      else
        this.language = 'fr';
    },
  },
  computed: {
    getHeight() {
      if(this.allowPagination) {
        return "76px";
      }
      return (window.innerHeight+100)/(this.services.length) <= 76 ? (window.innerHeight+100)/(this.services.length)+"px": '76px';
    },
    getBackButtonBorder() {
      return this.booking ? this.themeTemplate.services.mainButtonTextColor : this.themeTemplate.services.mainButtonBackground;
    },
    showNextButton () {
      if(this.themeTemplate.allowCategory == 1 && !this.categoryChosen) {
        return false;
      } else {
        return (+JSON.parse(JSON.parse(window.localStorage.getItem('kiosk')).config).allow_multiple_services || JSON.parse(window.localStorage.getItem('kiosk')).allow_sms_ticket);
      }
    },
    phoneNumberNotAllowed() {
      return !JSON.parse(window.localStorage.getItem('kiosk')).allow_sms_ticket;
    },
    getBackground() {
      if(this.popup)
        return "#FFFFFF";
      else if (this.booking) {
        return this.themeTemplate.booking.background;
      }
      else if(this.themeTemplate.services.background.includes('base64')) {
        return `url(${this.themeTemplate.services.background})`;
      }
      return this.themeTemplate.services.background;
    },
    servicesPage() {
      if(this.allowPagination) {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        return this.services.slice(startIndex, startIndex + this.itemsPerPage);
      }
      return this.services;
    },
    categoriesPage() {
      if(this.allowPagination) {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        return this.categories.slice(startIndex, startIndex + this.itemsPerPage);
      }
      return this.categories;
    },
    isDisabled() {
      return this.modelValue;
    },
    getTextColor(){
      return this.themeTemplate.textColor;
    },
    getServiceBg() {
      if(this.booking && !this.themeTemplate.services.serviceBackground.includes('base64')) {
        if(this.themeTemplate.services.background.includes('base64')) {
          return `url(${this.themeTemplate.services.background})`;
        }
      }
      if(this.themeTemplate.services.serviceBackground.includes('base64')) {
        return `url(${this.themeTemplate.services.serviceBackground})`;
      }
      return this.themeTemplate.services.serviceBackground;
    },
    getServiceTextColor() {
      if(this.booking && (this.themeTemplate.services.serviceBackground.includes('base64') || this.themeTemplate.services.background.includes('base64'))) {
        return this.themeTemplate.textColor;
      }
      return this.themeTemplate.services.serviceTextColor;
    },
    getTitleColor() {
      if(this.booking)
        return this.themeTemplate.booking.titleColor;
      return this.themeTemplate.services.titleColor;
    },
    getCategoryTitle() {
      if(this.$i18n.locale == "fr")
        return this.themeTemplate.categoryTitleTextFr;
      else if(this.$i18n.locale == "ar")
        return this.themeTemplate.categoryTitleTextAr;
      else
        return this.themeTemplate.categoryTitleTextEn;
    }
  }
}
</script>

<style scoped>
.servicesPaginationsContainer{
  height: 88%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.paginator-holder{
  height: 3vmin;
}
.titleContainer{
  color:v-bind(getTitleColor);
  font-family: 'Exo 2',serif;
  font-style: normal;
  font-weight: 400;
  font-size: 4vmin;
  line-height: 3vmin;
  margin: 4vmin;
}
.buttons-container{
  margin-bottom: 2vh;
  padding-left: 4vmin;
  padding-right: 4vmin;
  width: 100% !important;
}
.mainContainer{
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
  width: 100% !important;
}
.labelContainer{
  width: 100%;
  white-space: break-spaces;
  word-break: break-word;
  overflow: hidden;
  font-family: 'Exo 2',serif;
  font-style: normal;
  font-weight: 400;
  color: v-bind(getServiceTextColor);
  text-align: left;
  margin: auto 2vmin auto 2vmin;

  padding-bottom: 3px;
  padding-top: 3px;
  display: flex;
  gap: 15px;
  align-items: center;
}
.singleService{
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  cursor: pointer;
  height: v-bind(getHeight);
  margin-bottom: 2vmin;
  padding:v-bind(paddingTop) 5vmin;
  width: 92%;
  /* identical to box height */
  background: v-bind(getServiceBg);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 0px;
}
.fifthThemeContainer{
  background:v-bind(getBackground);
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.nextButtonContainer{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 4vmin;
}
</style>