<template>
<div class="button-container">
  <div :class="{'right-class': $i18n.locale === 'ar'}" class="label-style">{{label}}</div><br>
  <img v-if="icon" class="icon-style" :src="getIcon()" alt=""/>
  <input :autofocus="focus" @focus="$emit('focused')" class="input-style" :style="{'box-shadow': boxShadow ? boxShadow: '','height': height ? height: '', 'padding-left': icon && $i18n.locale !== 'ar' ? '9vmin' : '', 'padding-right': icon && $i18n.locale === 'ar' ? '9vmin' : ''}" :type="type" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"/>
</div>
</template>
<script>
export default {
    name: 'LbrxInput',
    props: {
      label:{
          required: true,
          value: String
      },
        focus:{
            required: false,
            value: Boolean
        },
        boxShadow:{
            required: false,
            value: String
        },
        height:{
            required: false,
            value: String
        },
      icon:{
          required: false,
          value: String
      },
      type:{
          required: true,
          value: String
      },
      modelValue:String,
    },
    data() {
        return {
            themeTemplate: null,
        }
    },
    emits: ['update:modelValue', 'focused'],
    created() {
        this.themeTemplate = JSON.parse(window.localStorage.getItem('theme'));
    },
    methods: {
        getIcon() {
            var images = require.context('../assets/')
            return images('./icons/' + this.icon + ".svg")
        },
    },
    computed: {
        getPrimary() {
            return this.themeTemplate.primaryColor;
        }
    }
}
</script>

<style scoped>
.button-container{
    width: 100% !important;
    text-align: left;
    padding: 0.3vmin;
}
.label-style{
    margin-bottom: -2vb;
    width: 100%;
    text-align: left;
    font-family: 'Exo 2',sans-serif;
    font-style: normal;
      font-weight: 400;
      font-size: 3.1vmin;
    line-height: 3.1vmin;

    /* Medium Gray */

    color: #8A8A8A;
}
.input-style{
    background: #FCFCFC;
    border: 1px solid #E4E4E4;
    height: 8.1vmin;
    width: 100%;
    margin-bottom: 4vb;
    padding-left: 2vmin;
    padding-right: 2vmin;
    font-size: 3vmin;
    -webkit-appearance: none !important;
    outline: none;
}
.input-style:focus {
    border: 2px solid v-bind(getPrimary) !important;
    -webkit-appearance: none !important;
}
.icon-style{
    height: 3.7vmin;
    z-index: 9;
    position: absolute;
    margin-left: 2vmin;
    margin-top: 2.2vmin;
}
</style>