<template>
    <div class="header-container justify-content-between">
        <img style="height: 7vh !important;" class="logo" :src="getSrc">
        <div :class="{'right-class': $i18n.locale === 'ar'}" class="buttons-container">
            <router-link v-if="isQueue && bookingAllowed" to="/booking">
                <button ref="rnvButton" id="rnvButton" :disabled="modelValue" :style="getBorderBookingStyle" class="btn rndvButton">
                    <div style="margin: auto"><img style="height: 4.3vh" src="../assets/icons/booking-icon.svg"/></div>
                <div :style="{'padding-left': $i18n.locale !== 'ar' ? '1vmin': '', 'padding-right': $i18n.locale === 'ar' ? '1vmin': ''}" class="button-label">{{ $t('Kiosk.rndvdButtonBook') }}</div>
            </button>
            </router-link>

            <router-link v-else-if="bookingAllowed" to="/waitingQueue">
                <button id="queueBtn" ref="queueBtn" :disabled="modelValue" :style="getBorderBookingStyle" class="btn rndvButton">
                <div style="margin: auto"> <img style="height: 4.3vh" src="../assets/icons/waiting-queue-button.svg"/></div>
                <div :style="{'padding-left': $i18n.locale !== 'ar' ? '1vmin': '', 'padding-right': $i18n.locale === 'ar' ? '1vmin': ''}" class="button-label">{{ $t('Kiosk.waitingQueueButton') }}</div>
            </button>
            </router-link>
            <button id="languageBtn" :disabled="modelValue" @click="showLanguagePopup = true" class="btn languageButton">
                <div style="margin: auto"> <img style="height: 4.3vh" src="../assets/icons/global-white.svg"/></div>
                <div :style="{'padding-left': $i18n.locale !== 'ar' ? '1vmin': '', 'padding-right': $i18n.locale === 'ar' ? '1vmin': ''}" class="button-label">{{ $t('Kiosk.languageTitle') }}</div>
            </button>
        </div>
        <LbrxLanguageSelectPopup v-if="showLanguagePopup" @close="showLanguagePopup = false" v-model="showLanguagePopup"></LbrxLanguageSelectPopup>
    </div>
</template>
<script>
import colorShader from "@/_helpers/color-shader";
import router from "@/router";
import LbrxLanguageSelectPopup from "@/components/LbrxLanguageSelectPopup.vue";
export default {
    name :'LbrxKioskHeader',
    components: {LbrxLanguageSelectPopup},
    props: {
        primaryColor: {required: true, type:String},
        secondaryColor: {required: true, type:String},
        hasBorderRadius: {required: true, type:Boolean},
        textColor: {required: true, type:String},
        headerBackground: {required: true, type:String},
        modelValue: {required: true, type: Boolean},
        logo: {required: true, type: String},
        themeTemplate: {required: false, type: Object, default: null},
    },
    data() {
      return {
          showLanguagePopup:false,
      }
    },
    computed: {
        getSrc() {
           return this.logo;
        },
        isQueue() {
          return this.$router.currentRoute._value.name === 'waitingQueue';
        },
        bookingAllowed() {
            return JSON.parse(window.localStorage.getItem('kiosk')).allow_booking;
        },
        getBorderBookingStyle() {
            if(this.themeTemplate) {
                if(this.themeTemplate.allowCategory == 1)
                    return '';
                else {
                    return {
                        borderRadius: this.hasBorderRadius && this.$i18n.locale !== 'ar' ? '0 0 0 10vmin' : this.hasBorderRadius && this.$i18n.locale === 'ar' ? '0 0 10vmin 0' :  '0', 'padding-left': this.hasBorderRadius && this.$i18n.locale !== 'ar' ? '5vmin' : '', 'padding-right': this.hasBorderRadius && this.$i18n.locale === 'ar' ? '5vmin' : ''
                    }
                }
            } else {
                return {
                    borderRadius: this.hasBorderRadius && this.$i18n.locale !== 'ar' ? '0 0 0 10vmin' : this.hasBorderRadius && this.$i18n.locale === 'ar' ? '0 0 10vmin 0' :  '0', 'padding-left': this.hasBorderRadius && this.$i18n.locale !== 'ar' ? '5vmin' : '', 'padding-right': this.hasBorderRadius && this.$i18n.locale === 'ar' ? '5vmin' : ''
                }
            }
            
        }
    }
}
</script>

<style scoped>
.header-container{
    padding-top: 1.3vmin;
    padding-bottom: 1.3vmin;
    background: v-bind(headerBackground) !important;
    display: flex;
    width: 100%;
    height: 11vh;
    padding-right: 4vmin;
}
.header-container .logo {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 4vmin;
}

.header-container .buttons-container{
    width: auto;
    display: flex;
    justify-content: right;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
}
.languageButton{
    background: v-bind(primaryColor);
    width: auto;
    display: flex;
    border-radius: 0 !important;
    height: 8vh;
}
.rndvButton{
    background: v-bind(secondaryColor);
    width: auto;
    min-width: 170px;
    height: 8vh;
    margin-right: 1vmin;
    display: flex;
}
.button-label {
    margin: auto;
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 3vh;
    text-align: center;
    color: v-bind(textColor);
}
</style>