<template>
  <div v-if="modelValue" class="popup-container">

        <Transition name="fade">
        <div v-if="showPopup" class="popup-box">
      <div class="container d-flex flex-column">
      <div class="row">
      <div :class="{'right-class': $i18n.locale !== 'ar'}" style="width: 100%;display: flex;padding: 0 !important;" class="close-popup-container justify-content-between">
          <div @click="closeThis()" class="close-popup">
              <div style="margin: auto" :style="{'color': mainTheme.primaryColorf}">X</div>
          </div>
          <div :style="{'direction': $i18n.locale !== 'ar' ? 'ltr': 'rtl'}">
              <div style="margin-top:1vmin " class="popup-title">
                  <img style="margin: auto 1vmin auto 1vmin;" class="popup-title-icon" src="../assets/icons/language-icon.svg" alt=""/>
                  <div class="popup-title-text">
                      {{ $t('Kiosk.languageTitle') }}
                  </div>
              </div>
          </div>
      </div>
      </div>
      <div class="row">
          <div v-if="!loading" class="popup-body">
              <div class="language-container">
                 <div style="margin-bottom: 5px"><lbrx-button @clicked="setLanguage('fr')" label="Français" color="#8A8A8A" background="white" height="9.5vh" width="80%" font-size="1" border="1px solid #EAEAEA"></lbrx-button></div>
                  <div style="margin-bottom: 5px"><lbrx-button @clicked="setLanguage('en')" label="English" color="#8A8A8A" background="white" height="9.5vh" width="80%" font-size="1" border="1px solid #EAEAEA"></lbrx-button></div>
                  <lbrx-button @clicked="setLanguage('ar')" label="العربية" color="#8A8A8A" background="white" height="9.5vh" width="80%" font-size="1" border="1px solid #EAEAEA"></lbrx-button>
              </div>
          </div>
      </div>
      </div>
    </div>
      </Transition>
  </div>
</template>

<script>

import LbrxFooterButton from "@/components/LbrxFooterButton.vue";
import LbrxButton from "@/components/LbrxButton.vue";

export default {
    name: 'LbrxLanguageSelectPopup',
    components: {LbrxButton},
    props: {
        modelValue:Boolean,
    },
    data() {
        return {
            showPopup:false,
            loading:true,
            mainTheme: null,
        }
    },
    methods: {
        setLanguage(lang) {
          localStorage.setItem('Language', lang)
          this.$i18n.locale = localStorage.getItem('Language')
          this.closeThis();
        },
        closeThis() {
            this.showPopup = false;
            setTimeout(()=>{
                this.$emit('close');
            },700)
        },
    },
    created() {
        this.mainTheme = JSON.parse(window.localStorage.getItem('theme'));
        document.addEventListener('keyup',(event)=>{
            event.key === 'Escape' ? this.closeThis():null;
        });
        this.loading = false;
        setTimeout(()=>{
            this.showPopup = true;
        },5)
    },
}
</script>

<style scoped>
@media (max-width: 1200px) {
    .popup-box{
        width: 35% !important;
    }

}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
.language-container{
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin-top: 2vmin;
    margin-bottom: 2vmin;
    width: 100%;
}
.popup-box{
    width: 28%;
    margin: auto;
    overflow-y: hidden !important;
    height: fit-content !important;
}
.popup-title-text{
    font-size: 1.8vw;
    margin: auto;
}
.popup-title {
    margin-left: 2vmin;
    margin-right: 2vmin;
    display: flex;
    width: fit-content;
}
.close-popup{
    background: #A3A3A3;
    height: 50px;
    width: 50px;
    font-weight: 700;
    color: v-bind(textColor);
    font-size: 30px;
    line-height: 30px;
    display: flex;

}
</style>