<template>
<div class="firstThemeContainer">

    <div class="servicesPaginationsContainer">
  <div v-if="!popup"  class="languageSelectContainer">
      <div @click="changeLanguage()" class="languageButtons justify-content-between">
      <div class="languageButton"  @click="$emit('setLanguage', 'ar')" :class="{'selectedLeftButton': $i18n.locale === 'ar'}">
          العربية
      </div>
      <div class="languageButton"  @click="$emit('setLanguage', 'fr')" :class="{'selectedRightButton': $i18n.locale !== 'ar'}">
          Français
      </div>
      </div>
  </div>
  <div class="mainContainer">
          <div :class="{'right-class': $i18n.locale === 'ar'}" class="row">
            <div :class="{'col-6': popup || servicesPage.length < 3, 'col-4': !popup && servicesPage.length > 2}" v-for="(service, index) in servicesPage" :id="'service'+service.id" :key="index" >
              <div :style="{'border-left': service.checked && $i18n.locale !== 'ar' ? '12px solid '+themeTemplate.secondaryColor : '1.5vmin solid'+getPrimary, 'border-right': service.checked && $i18n.locale === 'ar' ? '12px solid '+secondaryColor : '1.5vmin solid'+makeColorLighter, 'opacity': service.disabled ? '0.7':'1'}" :title="service.title" v-long-press:@click="()=>handleLongPress(service)" @mouseup="handleClick(service)" @touchend="()=>handleTouch(service)" class="singleService">
                  <div :class="{'right-class': $i18n.locale === 'ar'}" v-if="service.translations && JSON.parse(service.translations)[$i18n.locale] != null" class="labelContainer" :style="{'margin-left': service.checked && $i18n.locale !== 'ar'? '1px': '', 'margin-right': service.checked && $i18n.locale === 'ar'? '1px': '', 'font-size': getFontSize(JSON.parse(service.translations)[$i18n.locale]) , 'line-height': getFontSize(JSON.parse(service.translations)[$i18n.locale])}"> {{JSON.parse(service.translations)[$i18n.locale]}} <span style="font-weight:600;margin-left:5px;margin-right:5px" v-if="service.checked  && service.qte">(x{{service.qte}})</span></div>
                  <div :class="{'right-class': $i18n.locale === 'ar'}" v-else-if="service.title" class="labelContainer" :style="{'margin-left': service.checked && $i18n.locale !== 'ar'? '1px': '', 'margin-right': service.checked && $i18n.locale === 'ar'? '1px': '', 'font-size': getFontSize(service.title) , 'line-height': getFontSize(service.title)}"> {{service.title}} <span style="font-weight:600;margin-left:5px;margin-right:5px" v-if="service.checked  && service.qte">(x{{service.qte}})</span></div>
              </div>
            </div>
          </div>
  </div>
    <lbrx-services-paginator v-if="pagesCount > 1" @next-page="nextPage" @prev-page="prevPage" @set-page="setPage" :theme-template="themeTemplate" v-model="currentPage" :pages-count="pagesCount"></lbrx-services-paginator>
    <div v-else class="paginator-holder">

    </div>
</div>
  <div v-if="!popup && showNextButton" :style="{'margin-bottom': popup ? '6.5vmin': ''}" class="nextButtonContainer">

      <lbrx-button id="validateButton" v-model="isDisabled"
                   @clicked="$emit('validate')" :label="phoneNumberNotAllowed ? $t('Kiosk.printTicketButton') :$t('Kiosk.nextButton')" :color="themeTemplate.textColor"
                   :background="makeColorDarker" height="8vmin" width="20%" font-size="1.3"></lbrx-button>
  </div>
</div>
</template>

<script>
import colorShader from "@/_helpers/color-shader";
import LbrxButton from "@/components/LbrxButton.vue";
import LbrxServicesPaginator from "@/components/LbrxServicesPaginator.vue";

import longPressDirective from '../_helpers/longPressDirective';
export default {
    name: "LbrxKioskFourthTheme",
    components: {LbrxServicesPaginator, LbrxButton},
    props: {
        themeTemplate: {required: true, type: Object},
        services: {required: true, type: Array},
        modelValue: {required: true, type: Boolean},
        popup: {required: true, type: Boolean},
    },
    directives: {
        'long-press': longPressDirective,
    },
    emits: ['validate', 'checkService', 'long-press', 'setLanguage'],
    data() {
        return {
            language: 'fr',
            currentPage:1,
            pagesCount:0,
            itemsPerPage:15,
        }
    },
    created() {
        this.pagesCount = Math.ceil(this.services.length / this.itemsPerPage);
        if(this.popup) {
            this.itemsPerPage = 2;
        }
    },
    methods: {
        getFontSize(label) {
            if(label.length > 100) {
                return "16px";
            }
            if(label.length > 50) {
                return "21px";
            }
            return "26px";
        },
        handleLongPress(service) {
            console.log(service);
            this.longPress = true;
            this.$emit('long-press', service);
        },
        handleClick(service) {
            this.$emit('checkService', service);
        },
        handleTouch(service) {
            if(this.longPress) {
                this.longPress = false;
                return;
            }
            this.$emit('checkService', service);
        },
        setPage(pageNumber) {
            if(this.currentPage !== pageNumber)
                this.currentPage = pageNumber;
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.pagesCount) {
                this.currentPage++;
            }
        },
        changeLanguage() {
            if(this.language === 'fr')
                this.language = 'ar';
            else
                this.language = 'fr';
        },

    },
    computed: {
        showNextButton () {
            return (+JSON.parse(JSON.parse(window.localStorage.getItem('kiosk')).config).allow_multiple_services || JSON.parse(window.localStorage.getItem('kiosk')).allow_sms_ticket);
        },
        phoneNumberNotAllowed() {
            return !JSON.parse(window.localStorage.getItem('kiosk')).allow_sms_ticket;
        },
        servicesPage() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            return this.services.slice(startIndex, startIndex + this.itemsPerPage);
        },
        isDisabled() {
            return this.modelValue;
        },
        makeSecondaryColorLighter(){
            return colorShader.methods.newShade(this.themeTemplate.secondaryColor, 50);
        },
        makeColorDarker(){
            return colorShader.methods.newShade(this.themeTemplate.primaryColor, -50);
        },
        makeColorLighter(){
            return colorShader.methods.newShade(this.themeTemplate.primaryColor, 50);
        },
        getPrimary() {
            return this.themeTemplate.primaryColor;
        },
        getTextColor() {
            return this.themeTemplate.textColor;
        }
    }
}
</script>

<style scoped>
.servicesPaginationsContainer{
    height: 88%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.paginator-holder{
    height: 3vmin;
}
.mainContainer{
    padding: 1vmin;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100% !important;
}
.singleService{
     display: flex;
     cursor: pointer;
     background: linear-gradient(127.19deg, v-bind(getPrimary) 0.56%, v-bind(makeColorLighter) 76.01%) !important;
      height: 70px;
     margin-bottom: 2vmin;
     width: 100%;
 }
.firstThemeContainer{
    overflow-x: hidden;
    padding: 2vmin;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.labelContainer{
    padding-bottom: 3px;
    padding-top: 3px;
    margin:auto;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    text-align: left;
    white-space: break-spaces;
    word-break: break-word;
    overflow: hidden;
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: v-bind(getTextColor);
}
.languageButton{
    width: 50%;
    padding: 2vmin;
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2.7vmin;
    line-height: 3.5vmin;
    color: v-bind(getPrimary)
}
.selectedLeftButton{
    margin-left: -3vmin;
    width: 65%;
    background: linear-gradient(127.19deg, v-bind(getPrimary) 0.56%, v-bind(makeColorLighter) 76.01%) !important;
    color: v-bind(getTextColor);
}

.selectedRightButton {
    margin-right: -3vmin;
    width: 65%;
    background: linear-gradient(127.19deg, v-bind(getPrimary) 0.56%, v-bind(makeColorLighter) 76.01%) !important;
    color: v-bind(getTextColor);
}
.languageButtons {
    cursor: pointer;
    padding-left:3vmin;
    padding-right:3vmin;
    display: flex;
    width: 30vw;
    border: 1px solid v-bind(getPrimary);
}
.languageSelectContainer{
    display: flex;
    width: 100%;
    justify-content: center;
}

.nextButtonContainer{
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>