import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import LoginPage from '../views/login/LoginPage.vue'
import MaintenacePage from "@/views/maintenance/MaintenacePage.vue";
import KioskWaitingQueue from "@/views/kioskWaitingQueue/KioskWaitingQueue.vue";
import KioskBooking from "@/views/kioskBooking/KioskBooking.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: "/waitingQueue"
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        meta: {
            authRequired: true,
            authorize: ["*"],
        },
        component: MaintenacePage
    },
    {
        path: '/waitingQueue',
        name: 'waitingQueue',
        meta: {
           authRequired: true,
           authorize: ["*"],
       },
        component: KioskWaitingQueue
    },
    {
        path: '/booking',
        name: 'booking',
        meta: {
           authRequired: true,
           authorize: ["*"],
       },
        component: KioskBooking
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {

    const userLoggedIn = localStorage.getItem('kiosk');
    if (to.meta.authRequired && userLoggedIn === null) {
        return next('/login');
    }
    if (to.path == '/login' && userLoggedIn !== null) {
        return next('/');
    }
    if (to.name == "Host") {
        if (!process.env.VUE_APP_EDIT_HOST || process.env.VUE_APP_EDIT_HOST == "false") {
            return next('/kioskWaitingQueue');
        }
    }

    next();
})

export default router
