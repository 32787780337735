<template>
    <div id="quantityPopup" v-if="modelValue" class="popup-container">
        <Transition name="fade">
            <div v-if="showPopup" class="popup-box">
                <div class="popup-body">
                    <div>
                    <div class="title">
                        {{$t('Kiosk.quantityTitle')}}
                    </div>
                    <div class="row">
                        <div class="slider-label col-1">
                            <span v-if="$i18n.locale !=='ar'">{{min}}</span>
                            <span v-else>{{max}}</span>
                        </div>
                        <div class="col-10">
                         <Slider class="slider-class" @start="dragging=true" @update="$emit('submitQuantity', sliderValue)" @end="dragging=false" v-model="sliderValue" :min="min" :max="max" :tooltips="true" showTooltip="always" :direction="$i18n.locale === 'ar' ? 'rtl': 'ltr'"/>
                        </div>
                        <div class="slider-label col-1">
                            <span v-if="$i18n.locale !=='ar'">{{max}}</span>
                            <span v-else>{{min}}</span>
                        </div>
                    </div>
                    <div class="quantity">
                        {{$t('Kiosk.quantity')}} {{sliderValue}}
                    </div>
                </div>
                    <div class="buttons-container">
                        <lbrx-button v-model="dragging"
                                     @clicked="closeThis" :box-shadow="'0px 4px 4px rgba(0, 0, 0, 0.25)'" :label="$t('Kiosk.confirmButton')" :color="getTextColor"
                                     :background="getPrimaryColor" height="7vmin" width="30%" font-size="1.3"></lbrx-button>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>
<script>


import Slider from '@vueform/slider'
import colorShader from "@/_helpers/color-shader";
import LbrxButton from "@/components/LbrxButton.vue";
export default {
    name:'LbrxQuantityPickerPopup',
    components: {LbrxButton, Slider},
    emits:['submitQuantity','close'],
    props: {
        modelValue:Boolean,
        themeTemplate:{required:true, type:Object},
        max:{required:true, type:Number},
        min:{required:true, type:Number},
    },
    data() {
        return {
            dragging:false,
            sliderValue:0,
            showPopup:false,
            loading:false,
        }
    },
    methods: {
        closeThis() {
            this.showPopup = false;
            setTimeout(()=>{
                this.$emit('close');
            },400)
        },
    },
    computed: {
        getTextColor() {
          return this.themeTemplate.textColor;
        },
        getPrimaryColor() {
            return this.themeTemplate.primaryColor;
        },
        getSecondaryColor() {
            return this.themeTemplate.secondaryColor;
        },
    },
    created() {
        this.sliderValue = this.min;
        document.addEventListener('keyup',(event)=>{
            event.key === 'Escape' ? this.closeThis():null;
        });
        this.loading = false;
        setTimeout(()=>{
            this.showPopup = true;
        },1);
    },
}
</script>

<style scoped>

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.popup-container{
    background: rgba(0,0,0,0.7);
    display: flex !important;
    justify-content: center !important;
}
.popup-box {
    margin: auto;
    overflow-y: hidden !important;
    width: 70%;
    height: 40%;
    background: #FFFFFF;
}
.popup-body {
    margin-top: 5vmin;
    padding: 20px;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.slider-class{
    --slider-connect-bg: v-bind(getSecondaryColor);
    --slider-bg: v-bind(getSecondaryColor);
    --slider-tooltip-bg: v-bind(getPrimaryColor);
    --slider-handle-bg:  v-bind(getPrimaryColor);
}
.slider-label{
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    color: v-bind(getPrimaryColor);
    margin-top: -15px;
}
.title{

    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 6vmin;
    color: v-bind(getPrimaryColor);
}
.quantity{
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 31px;

    /* Medium Gray */

    color: #8A8A8A;
}
.buttons-container{
    margin-bottom: 30px !important;
    justify-content: center;
    display: flex;
    width: 100% !important;
}
</style>
<style src="@vueform/slider/themes/default.css"></style>
