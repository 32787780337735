<template>
    <div class="form-container">
        <div>
            <lbrx-kiosk-header :themeTemplate="themeTemplate" :logo="themeTemplate.header.logo" :hasBorderRadius="themeTemplate.header.borderRadius"  v-model="validating" :primaryColor="themeTemplate.primaryColor" :secondaryColor="themeTemplate.secondaryColor" :text-color="themeTemplate.textColor" :header-background="themeTemplate.header.headerBackground"></lbrx-kiosk-header>
        </div>
        <div class="form-title">
            {{ $t('Kiosk.formTitle') }}
        </div>
        <div class="mainCon">
            <div style="padding-left:30px;padding-right:30px;" :class="{'right-class': $i18n.locale === 'ar'}" class="row">
            <div class="col-6">
                <lbrx-input id="fnameInput" type="text" @focused="focused = 'fname'" :box-shadow="'0px 4px 4px rgba(0, 0, 0, 0.25)'" :focus="true" :height="'70px'" :label="$t('Kiosk.lastName')" :model-value="fname"
                            v-model="fname" icon=""></lbrx-input>
            </div>
                <div class="col-6">
                <lbrx-input id="lnameInput" type="text" @focused="focused = 'lname'" :box-shadow="'0px 4px 4px rgba(0, 0, 0, 0.25)'" :label="$t('Kiosk.firstName')" :height="'70px'"   :model-value="fname"
                            v-model="lname" icon=""></lbrx-input>
            </div>
            </div>

            <div class="keyboardContainer">
                <LbrxKeyboard :selectedLanguage="$i18n.locale" @remove-letter="removeLetter" @add-letter="addLetter" :text-color="themeTemplate.primaryColor" :click-color="themeTemplate.secondaryColor" :primary-color="themeTemplate.booking.background" :secondary-color="themeTemplate.textColor"></LbrxKeyboard>
            </div>
        </div>
        <div class="nextButtonContainer">
            <lbrx-button id="cancelCreateCustomerButton" v-model="isDisabled" :color="themeTemplate.primaryColor" @clicked="$emit('cancel')" :border="'1px solid '+ themeTemplate.primaryColor" :label="$t('Kiosk.backButton')" :background="'transparent'" width="90%" :height="'9vmin'" font-size="1.5"></lbrx-button>
            <lbrx-button id="createCustomerButton" v-model="isDisabled"
                         @clicked="submitData"
                         :box-shadow="'0px 4px 4px rgba(0, 0, 0, 0.25)'" :label="$t('Kiosk.nextButton')" :color="themeTemplate.textColor"
                         :background="themeTemplate.primaryColor" height="9vmin" width="90%" font-size="1.5"></lbrx-button>
        </div>

        <div class="footer-container">
            <the-footer :color="themeTemplate.textColor" v-model="onLine" :background="themeTemplate.footer.footerBackground"></the-footer>
        </div>
    </div>
    <lbrx-kiosk-popup v-if="showPopup" @close="showPopup = false" v-model="showPopup" :closable="isClosable" :box-height="boxHeight" :content="boxContent" :icon="boxIcon" :text-color="boxTextColor" :box-background="boxBackGround" :icon-color="boxIconColor"></lbrx-kiosk-popup>
</template>
<script>
import LbrxButton from "@/components/LbrxButton.vue";
import LbrxKioskPopup from "@/components/LbrxKioskPopup.vue";
import LbrxKioskHeader from "@/components/LbrxKioskHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import LbrxInput from "@/components/LbrxInput.vue";
import LbrxKeyboard from "@/components/LbrxKeyboard.vue";
export default {
    name:'LbrxCreateCustomer',
    components: {LbrxButton, LbrxKeyboard, LbrxInput, TheFooter, LbrxKioskHeader, LbrxKioskPopup},
    props: {
      themeTemplate: {required:true, type:Object},
    },
    data() {
        return {
            fname:'',
            lname:'',
            onLine: navigator.onLine,
            validating: false,
            showPopup: false,
            focused:'fname',
            boxHeight:'40%',
            boxContent: '',
            boxIcon: 'loader',
            boxTextColor: '',
            isClosable: false,
            boxBackGround: '#FFFFFF',
            boxIconColor: this.themeTemplate.secondaryColor,
        }
    },
    emits: ['cancel', 'submit'],
    created() {
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
    },
    methods: {
        showErrorPopup(errorText) {
            this.showPopup = false;
            this.boxIcon = 'error';
            this.isClosable = true;
            this.boxContent = errorText;
            this.boxTextColor = '#EB5757'
            this.boxBackGround = '#FFFFFF';
            this.boxIconColor = '#EB5757';
            this.boxHeight = "40%";
            setTimeout(()=>{
                this.showPopup = true;
            },10)
        },
        submitData() {
            if(!this.fname.replace(/\s+/g, '').length || !this.lname.replace(/\s+/g, '').length) {
                this.showErrorPopup(this.$t('Kiosk.formError'));
                return;
            }
            this.$emit('submit', {fname:this.fname, lname:this.lname});
        },
        addLetter(letter) {
            if(this.focused === 'fname') {
                this.fname+=letter;
            } else {
                this.lname+=letter;
            }
        },
        removeLetter() {
            if(this.focused === 'fname') {
                this.fname =this.fname.slice(0,-1);
            } else {
                this.lname =this.lname.slice(0,-1);
            }
        }
    },
    computed: {
        isDisabled() {
            return this.loadingSlots;
        },
        getTextColor() {
            return this.themeTemplate.textColor;
        },
        getSecondaryColor() {
            return this.themeTemplate.secondaryColor;
        },
        getPrimaryColor() {
            return this.themeTemplate.primaryColor;
        },
        getBackground() {
            if(this.themeTemplate.theme === "seventhTheme" || this.themeTemplate.theme === "eighthTheme"){
                return this.themeTemplate.secondaryColor;
            }
            if(this.themeTemplate.allowCategory == 1) {
                return this.themeTemplate.booking.background;
            }
            return "#E9E4E1";
        }
    }
}
</script>

<style scoped>
.form-title {
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    /* identical to box height */

    text-align: center;

    color: v-bind(getPrimaryColor);
}
.form-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh !important;
    background: v-bind(getBackground);
}
.footer-container {
    width: 100% !important;
    height: auto;
}
.mainCon{
    margin-top: 5vmin;
    margin-bottom: 7vmin;
    overflow: hidden !important;
}
.keyboardContainer{
    width: 100% !important;
    display: flex;
    justify-content: center;
}

.nextButtonContainer{
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: -70px;
    padding-left: 20%;
    padding-right: 20%;
}
</style>